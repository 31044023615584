<script setup lang="ts">
const props = defineProps<{
  to?: string;
  class?: string;
  disabled?: boolean;
}>();

const classes = computed(() =>
  cn(
    "w-full p-3 flex flex-col gap-3 relative focus-within:z-20 border bg-neutral-0 rounded-xl",
    "group/item focus-within:border-neutral-3 hocus:border-neutral-5 hover:bg-neutral-05",
    props.class
  )
);
</script>

<template>
  <li class="flex items-stretch">
    <NuxtLink v-if="!to || !disabled" :to :class="classes">
      <slot />
    </NuxtLink>
    <div v-else :class="classes">
      <slot />
    </div>
  </li>
</template>
